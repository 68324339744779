<template>
 <CCard>
  <CCardBody>
     <div class="clearfix">
      <button type="button" class="btn btn-primary float-xl-right" @click="navToNewQMSInspection">New Inspection</button>
    </div>
    <CDataTable :items="items"  
    :fields="fields"
     striped
      clickable-rows
      
       table-filter
        items-per-page-select
      :items-per-page="20"
       sorter
         :responsive="false"
      pagination>
        <template #index="data">
       <td>
         {{data.index +1}}
         </td>
     </template>
       <template #fcm="{item}">
        <td class='font-weight-bold'
          v-if="item.fcm==2"
        >FCM</td>
        <td v-else>Non-FCM</td>
      </template>
      <template #createdTime="{item}">
        <td class='font-weight-bold'
          v-if="item.createdTime"
        >{{ item.createdTime |dateParse()| dateFormat("MMM D, YYYY hh:mm A") }}</td>
        <td v-else>--</td>
      </template>
    
        <template #show_details="{item}">
        <td class="py-2">
        <CDropdown
                  togglerText="Actions"
                  color="info"
                >
                 <CDropdownItem :to="{name: 'QMSInspection', params: {qmsInspectionId: item.qmsInspectionId,mode: 'view'}}">View </CDropdownItem>
                 
           
            <ConfirmationModal
                      :parent="$refs.modalArea"
                      :description="'Are you sure to Delete'"
                      @on:ok="deleteInspection(item.qmsInspectionId)"
                    ></ConfirmationModal>
        </CDropdown> 

        
        </td>
      </template>
      </CDataTable>
  </CCardBody>
   <div ref="modalArea"></div>
  <CCardFooter align="right">

            <CButton class="mr-2" type="Cancel" size="sm" color="danger"  @click="cancel()">
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
 </CCard>
</template>

<script>
const fields = [
   { key: "index", _classes: 'font-weight-bold', _style: "min-width:200px" },
 { key: "details", label: "Details", _classes: 'font-weight-bold', _style: "min-width:200px" },
     { key: "fcm", label: "FCM", _classes: 'font-weight-bold', _style: "min-width:200px" },
    { key: "groups", label: "Groups", _classes: 'font-weight-bold', _style: "min-width:200px" },
   
  
  { key: "createdTime",label: "Created Date & Time",_classes: 'font-weight-bold', _style: "min-width:200px" },
  { key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
];
import Vue from "vue";
import ConfirmationModal from "../../containers/ConfirmationModal";
export default {
  name: "QMSInspections",
  components: {
     ConfirmationModal,
   },
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
    };
  },
  methods: {
    getQmsInspection() {
       this.apiGetSecure(process.env.VUE_APP_API_HOST + "/emapp/web/secure/qms/inspection/"+this.$route.params.qmsModelId)
        .then((response) => response.json())
        .then((data) => (this.items = data));
    },
  cancel() {
           //  this.$router.push({ name: "QMSTe" });
           window.history.back();
    },
    deleteInspection(qmsInspectionId) {
      console.log("delete called with", this.qmsInspectionId);
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/delete/qms/inspection/" +
         qmsInspectionId
      )
        .then((response) => response.json())
        .then((data) => {
          this.getQmsInspection();
             this.$nextTick(() => {
             this.$refs.vuetable.refresh();
           });
           window.location.reload();
        });
    },
   
    navToNewQMSInspection(){
       this.$router.push({ name: "NewQMSInspection", params: {qmsModelId: this.$route.params.qmsModelId, mode: "new" } });
    }
  },
  mounted() {
    this.getQmsInspection();
  },
};
</script>